import { BASE_PATH, BLOG_API_ENDPOINT, DEFAULT_OG_IMAGE, MAIN_DOMAIN } from 'globalConstants';
import { Article } from 'graphql/blog/queries';
import { NewsArticle, WithContext } from 'schema-dts';

export const getArticleJsonLD = (article?: Article) => {
  const { url: mediaUrl } = article?.featured_media?.data?.attributes ?? {};
  const ogImage = mediaUrl ?? DEFAULT_OG_IMAGE;
  const articleSchema: WithContext<NewsArticle> = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    name: article?.title,
    headline: article?.content,
    datePublished: article?.createdAt,
    image: {
      '@type': 'ImageObject',
      url: `${BLOG_API_ENDPOINT}${ogImage}`,
    },
    //author
    publisher: {
      '@type': 'Organization',
      url: BASE_PATH,
      logo: {
        '@type': 'ImageObject',
        url: `${MAIN_DOMAIN}/logos/atm-nha-colored.svg`,
      },
    },
  };

  return JSON.stringify(articleSchema);
};
