import { ApolloLink } from '@apollo/client';
import { mainClient } from 'apollo/client';
import { createAuthenticationLink, errorLink, mainLink } from 'apollo/link';
import Error404 from 'components/desktop/error-404';
import MobileError404 from 'components/mobile/error-404';
import { AtmnhaAuthenticationModel } from 'components/providers/authentication-provider';
import ListProvinceProvider from 'components/providers/list-province-provider';
import RealEstateConfigurationProvider from 'components/providers/real-estate-configuration-provider';
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_OG_IMAGE,
  DEFAULT_TITLE,
  MAIN_DOMAIN,
  SITE_MAP,
} from 'globalConstants';
import {
  ApprovalStatusEnumType,
  Direction,
  GET_REAL_ESTATES,
  GetRealEstatesData,
  GetRealEstatesVariables,
  ListRealEstate,
  NEAR_REAL_ESTATES,
  NearRealEstatesData,
  NearRealEstatesVariables,
  PostStatusType,
  SLUGIFY,
  Slug,
  SlugifyData,
  SlugifyVariables,
  StatisticRealEstate,
  StatisticRealEstateByProvince,
  TradingStatus,
} from 'graphql/main/queries';
import {
  NEAR_REAL_ESTATES_BY_PROVINCE,
  NearRealEstatesByProvinceData,
  NearRealEstatesByProvinceVariables,
} from 'graphql/main/queries/near-real-estates-by-province';
import { TypeOfDemand } from 'graphql/map/queries';
import { isEmpty, isEqual, isNil, isNumber, isString, lte, toNumber, toString } from 'lodash';
import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Context, Fragment, createContext } from 'react';
import { getBreadcrumbListJsonLD } from 'seo/schema';
import { detectDeviceByUserAgent, parseSortParamFromStringToObject } from 'utils';

const SearchResult = dynamic(() => import('components/desktop/search-result'));
const MobileSearchResult = dynamic(() => import('components/mobile/search-result'));

const DEFAULT_LIMIT_CARD_ITEM = 28;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, params, query } = context;
  const { headers, cookies } = req;
  const authenticationJSONString = toString(cookies['atmnha_authentication_cookie']);
  if (authenticationJSONString) {
    const { accessToken } = JSON.parse(authenticationJSONString) as AtmnhaAuthenticationModel;
    if (accessToken) {
      const authLink = createAuthenticationLink(accessToken);
      mainClient.setLink(ApolloLink.from([errorLink, authLink, mainLink]));
    }
  }
  const device = detectDeviceByUserAgent(headers['user-agent']);
  let pageData = null;
  const { [SITE_MAP.SEARCH_RESULT.INDEX]: searchResultPathname } = params ?? {};
  const { data: slugifyData } = await mainClient.query<SlugifyData, SlugifyVariables>({
    query: SLUGIFY,
    variables: {
      slug: isString(searchResultPathname) ? searchResultPathname : '',
    },
  });
  const { slugify } = slugifyData;

  if (slugify) {
    const {
      page: pageQuery,
      sort: sortQuery,
      search: searchQuery,
      areaFrom: areaFromQuery,
      areaTo: areaToQuery,
      priceFrom: priceFromQuery,
      priceTo: priceToQuery,
      hasAlley: hasAlleyQueyr,
      widthFrom: widthFromQuery,
      widthTo: widthToQuery,
      lengthFrom: lengthFromQuery,
      lengthTo: lengthToQuery,
      direction: directionQuery,
      bedroomCountFrom: bedroomCountFromQuery,
      bedroomCountTo: bedroomCountToQuery,
      toiletCountFrom: toiletCountFromQuery,
      toiletCountTo: toiletCountToQuery,
      floorCountFrom: floorCountFromQuery,
      floorCountTo: floorCountToQuery,
      postType: postTypeQuery,
      isAuthorizedRe: isAuthorizedReQuery,
      isForeclosure: isForeclosureQuery,
      isLeadRE: isLeadREQuery,
    } = query;
    const { filters } = slugify ?? {};
    const { typeOfDemand, realEstateType, provinceId, districtId, wardId, streetId, area, price } =
      filters as any;
    const [{ data: getRealEstatesData }, { data: nearRealEstatesData }] = await Promise.all([
      mainClient.query<GetRealEstatesData, GetRealEstatesVariables>({
        query: GET_REAL_ESTATES,
        variables: {
          approvalStatus: ApprovalStatusEnumType.approved,
          postStatus: [PostStatusType.active],
          tradingStatus: [TradingStatus.trading],
          limit: DEFAULT_LIMIT_CARD_ITEM,
          page: isNaN(toNumber(pageQuery)) || lte(toNumber(pageQuery), 0) ? 1 : toNumber(pageQuery),
          sort:
            isString(sortQuery) && !isEmpty(sortQuery)
              ? parseSortParamFromStringToObject(sortQuery)
              : { createdAt: -1 },
          searchString: isString(searchQuery) ? searchQuery : '',
          typeOfDemand,
          realEstateType,
          province: provinceId,
          district: districtId,
          ward: wardId,
          street: streetId,
          area: area
            ? { from: area.from, to: area.to }
            : (areaFromQuery && isNumber(toNumber(areaFromQuery))) ||
              (areaToQuery && isNumber(toNumber(areaToQuery)))
            ? { from: toNumber(areaFromQuery), to: toNumber(areaToQuery) }
            : undefined,
          price: price
            ? { from: price.from, to: price.to }
            : (priceFromQuery && isNumber(toNumber(priceFromQuery))) ||
              (priceToQuery && isNumber(toNumber(priceToQuery)))
            ? {
                from: toNumber(priceFromQuery),
                to: toNumber(priceToQuery),
              }
            : undefined,
          hasAlley: isNil(hasAlleyQueyr) ? undefined : isEqual(hasAlleyQueyr, 'true'),
          width:
            (widthFromQuery && isNumber(toNumber(widthFromQuery))) ||
            (widthToQuery && isNumber(toNumber(widthToQuery)))
              ? {
                  from: toNumber(widthFromQuery),
                  to: toNumber(widthToQuery),
                }
              : undefined,
          length:
            (lengthFromQuery && isNumber(toNumber(lengthFromQuery))) ||
            (lengthToQuery && isNumber(toNumber(lengthToQuery)))
              ? {
                  from: toNumber(lengthFromQuery),
                  to: toNumber(lengthToQuery),
                }
              : undefined,
          direction: directionQuery ? ([directionQuery] as Direction[]) : undefined,
          bedroomCount:
            (bedroomCountFromQuery && isNumber(toNumber(bedroomCountFromQuery))) ||
            (bedroomCountToQuery && isNumber(toNumber(bedroomCountToQuery)))
              ? {
                  from: toNumber(bedroomCountFromQuery),
                  to: toNumber(bedroomCountToQuery),
                }
              : undefined,
          toiletCount:
            (toiletCountFromQuery && isNumber(toNumber(toiletCountFromQuery))) ||
            (toiletCountToQuery && isNumber(toNumber(toiletCountToQuery)))
              ? {
                  from: toNumber(toiletCountFromQuery),
                  to: toNumber(toiletCountToQuery),
                }
              : undefined,
          floorCount:
            (floorCountFromQuery && isNumber(toNumber(floorCountFromQuery))) ||
            (floorCountToQuery && isNumber(toNumber(floorCountToQuery)))
              ? {
                  from: toNumber(floorCountFromQuery),
                  to: toNumber(floorCountToQuery),
                }
              : undefined,
          postType:
            postTypeQuery && isNumber(toNumber(postTypeQuery))
              ? [toNumber(postTypeQuery)]
              : undefined,
          isAuthorizedRe: isNil(isAuthorizedReQuery)
            ? undefined
            : isEqual(isAuthorizedReQuery, 'true'),
          isForeclosure: isNil(isForeclosureQuery)
            ? undefined
            : isEqual(isForeclosureQuery, 'true'),
          isLeadRE: isNil(isLeadREQuery) ? undefined : isEqual(isLeadREQuery, 'true'),
        },
      }),
      (slugifyData?.slugify?.filters as any)?.provinceId
        ? mainClient.query<NearRealEstatesData, NearRealEstatesVariables>({
            query: NEAR_REAL_ESTATES,
            variables: {
              isForSell: !isEqual(
                (slugifyData.slugify?.filters as any)?.typeOfDemand,
                TypeOfDemand.ForRent,
              ),
              province: (slugifyData.slugify?.filters as any)?.provinceId,
            },
          })
        : mainClient.query<NearRealEstatesByProvinceData, NearRealEstatesByProvinceVariables>({
            query: NEAR_REAL_ESTATES_BY_PROVINCE,
            variables: {
              isForSell: !isEqual(
                (slugifyData?.slugify?.filters as any)?.typeOfDemand,
                TypeOfDemand.ForRent,
              ),
            },
          }),
    ]);
    const { getRealEstates: realEstatesWithPagination } = getRealEstatesData;
    const nearRealEstates = (slugifyData?.slugify?.filters as any)?.provinceId
      ? (nearRealEstatesData as NearRealEstatesData).nearRealEstates
      : (nearRealEstatesData as NearRealEstatesByProvinceData).nearRealEstatesByProvince;

    pageData = {
      slugify: slugify ?? null,
      realEstatesWithPagination: realEstatesWithPagination ?? null,
      nearRealEstates: nearRealEstates ?? null,
    };
  }

  return {
    props: {
      device,
      pageData,
    },
  };
};

export const SearchResultPageContext: Context<Omit<Props, 'device'>> = createContext({});

interface Props {
  device?: 'mobile' | 'desktop';
  pageData?: {
    slugify?: Slug | null;
    realEstatesWithPagination: ListRealEstate | null;
    nearRealEstates: StatisticRealEstate[] | StatisticRealEstateByProvince[] | null;
  } | null;
}

const SearchResultPage: NextPage = (props: Props) => {
  const { device, ...restProps } = props;
  const { pageData } = restProps;
  const { slugify } = pageData ?? {};
  const { seoMetaTitle, seoMetaDescription, seoMetaImage, slug, breadScrumbs } = slugify ?? {};
  const title = seoMetaTitle ? `${seoMetaTitle} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  const description = seoMetaDescription ?? DEFAULT_DESCRIPTION;
  const ogUrl = slug ? `${MAIN_DOMAIN}/${slug}` : MAIN_DOMAIN;
  const ogImage = seoMetaImage ?? DEFAULT_OG_IMAGE;
  const breadcrumbs = breadScrumbs ?? [];
  const breadcrumbListJsonLD = getBreadcrumbListJsonLD(breadcrumbs);

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
        />
        <meta name='description' content={description} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description} />
        <meta name='og:url' content={ogUrl} />
        <meta name='og:image' content={ogImage} />
        <title>{title}</title>
      </Head>
      <SearchResultPageContext.Provider value={restProps}>
        <RealEstateConfigurationProvider>
          <ListProvinceProvider>
            {isEqual(device, 'mobile') ? (
              slugify ? (
                <MobileSearchResult />
              ) : (
                <MobileError404 />
              )
            ) : isEqual(device, 'desktop') ? (
              slugify ? (
                <SearchResult />
              ) : (
                <Error404 />
              )
            ) : null}
          </ListProvinceProvider>
        </RealEstateConfigurationProvider>
      </SearchResultPageContext.Provider>
      <script
        key='breadcrumb-search-result-json-ld'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: breadcrumbListJsonLD }}
      />
    </Fragment>
  );
};

export default SearchResultPage;
