import { map } from 'lodash';
import { RealEstateAgent, WithContext } from 'schema-dts';
import { BRANCH_NAME, DEFAULT_SOCIAL_MEDIA_LINKS } from 'globalConstants';
import {
  DEFAULT_ADDRESS,
  DEFAULT_ADDRESS_LOCALITY,
  DEFAULT_CONTACT_PHONE,
  DEFAULT_COUNTRY,
  DEFAULT_ZIP_CODE,
  MAIN_DOMAIN,
} from 'globalConstants';

export const getRealEstateAgentJsonLD = () => {
  const realEstateAgentSchema: WithContext<RealEstateAgent> = {
    '@context': 'https://schema.org',
    '@type': 'RealEstateAgent',
    name: BRANCH_NAME,
    image: `${MAIN_DOMAIN}/logos/atm-nha-colored.svg`,
    priceRange: '20',
    url: MAIN_DOMAIN,
    telephone: DEFAULT_CONTACT_PHONE,
    address: {
      '@type': 'PostalAddress',
      streetAddress: DEFAULT_ADDRESS,
      addressLocality: DEFAULT_ADDRESS_LOCALITY,
      postalCode: DEFAULT_ZIP_CODE,
      addressCountry: {
        '@type': 'Country',
        name: DEFAULT_COUNTRY,
      },
    },
    // geo: {
    //   '@type': 'GeoCoordinates',
    //   latitude: '',
    //   longitude: '',
    // },
    sameAs: DEFAULT_SOCIAL_MEDIA_LINKS,
  };

  return JSON.stringify(realEstateAgentSchema);
};
