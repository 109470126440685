import {
  BRANCH_NAME,
  DEFAULT_ADDRESS,
  DEFAULT_ADDRESS_LOCALITY,
  DEFAULT_SHORT_DESCRIPTION,
  DEFAULT_ZIP_CODE,
  MAIN_DOMAIN,
  DEFAULT_COUNTRY,
  DEFAULT_CONTACT_PHONE,
  DEFAULT_EMAIL_ADDRESS,
  COMPANY_NAME,
  DEFAULT_ALTERNATE_NAME,
  DEFAULT_COMPANY_DESCRIPTION,
  FOUNDER_NAME,
} from 'globalConstants';
import { Organization, WithContext } from 'schema-dts';

export const getOrganizationJsonLD = () => {
  const organizationSchema: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: COMPANY_NAME,
    alternateName: DEFAULT_ALTERNATE_NAME,
    description: DEFAULT_COMPANY_DESCRIPTION,
    founder: {
      '@type': 'Person',
      name: FOUNDER_NAME,
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: DEFAULT_ADDRESS,
      addressLocality: DEFAULT_ADDRESS_LOCALITY,
      postalCode: DEFAULT_ZIP_CODE,
      addressCountry: {
        '@type': 'Country',
        name: DEFAULT_COUNTRY,
      },
    },
    member: {
      '@type': 'Organization',
      name: COMPANY_NAME,
    },
    url: MAIN_DOMAIN,
    logo: `${MAIN_DOMAIN}/logos/atm-nha-colored.svg`,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: DEFAULT_CONTACT_PHONE,
      email: DEFAULT_EMAIL_ADDRESS,
      contactType: 'customer service',
      areaServed: DEFAULT_COUNTRY,
    },
  };

  return JSON.stringify(organizationSchema);
};
