import Footer from 'components/desktop/shared/footer';
import Header2 from 'components/desktop/shared/header2';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

const Error404 = () => {
  const router = useRouter();

  return (
    <Fragment>
      <Header2 />
      <main className='flex min-h-[1000px] min-w-[1052px] flex-col'>
        <div className='my-[28px] flex flex-col space-y-[28px]'>
          <div className='container mx-auto min-w-[1052px] px-[54px] screen-1140:px-[98px] xl:px-[48px] screen-1366:px-[83px]'>
            <div className=' flex w-full flex-col items-center space-y-[28px] rounded-[16px] bg-paper py-[28px] shadow-7'>
              <img
                src='/images/error-404.svg'
                alt='error-404'
                loading='lazy'
                className='h-[400px] w-full object-contain'
              />
              <span className='text-text2'>
                Xin lỗi, trang bạn đang tìm kiếm không tồn tại hoặc đã bị xoá.
              </span>
              <Link
                href='/'
                className='flex h-[40px] items-center justify-center rounded-[8px] bg-primary py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-primary-light'
              >
                <span className='text-paper'>Đi đến trang chủ</span>
              </Link>
              <button type='button' className='flex' onClick={() => router.back()}>
                <span className='text-primary'>Trở về trang trước</span>
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Error404;
