import Header2 from 'components/mobile/shared/header2';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

const MobileError404 = () => {
  const router = useRouter();

  return (
    <Fragment>
      <Header2 />
      <main>
        <div className='flex items-center p-[16px]'>
          <div className='flex w-full flex-col items-center space-y-[20px]'>
            <img
              src='/images/error-404.svg'
              alt='error-404'
              loading='lazy'
              className='h-[240px] w-full object-contain'
            />
            <span className='text-center text-text2'>
              Xin lỗi, trang bạn đang tìm kiếm không tồn tại hoặc đã bị xoá.
            </span>
            <Link
              href='/'
              className='flex h-[48px] items-center justify-center rounded-[8px] bg-primary py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-primary-light'
            >
              <span className='text-paper'>Đi đến trang chủ</span>
            </Link>
            <button type='button' className='flex' onClick={() => router.back()}>
              <span className='text-primary'>Trở về trang trước</span>
            </button>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default MobileError404;
