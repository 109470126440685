import { WebSite, WithContext } from 'schema-dts';
import { DEFAULT_ALTERNATE_NAME, MAIN_DOMAIN } from 'globalConstants';

export const getWebsiteJsonJD = () => {
  const websiteSchema: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: DEFAULT_ALTERNATE_NAME,
    alternateName: DEFAULT_ALTERNATE_NAME,
    url: MAIN_DOMAIN,
  };

  return JSON.stringify(websiteSchema);
};
