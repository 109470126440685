import { IconArrowLeft } from 'components/icons';
import { useRouter } from 'next/router';

const Back = () => {
  const router = useRouter();

  return (
    <button type='button' onClick={() => router.back()}>
      <IconArrowLeft className='w-[24px] h-[24px]' />
    </button>
  );
};

export default Back;
